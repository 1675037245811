<template>
    <!-- 移动端得 -->
    <div class="moveCharts">
        <div class="headerBox">
          <span>汇信云商BI</span>
        </div>
        <div class="namedesBox">
            <i @click="quitClick" class="el-icon-arrow-left"></i>
            <span>{{ paramsName }}</span>
        </div>
        <div class="actionBox">
            <div class="chartsBox" v-for="(item, index) in eChartsData" :key="index">
                <div class="searchChartsBox">
                    <i class="el-icon-search" @click="searchClick(item, index)"></i>
                </div>
                <div class="chartsActionBox">
                    <Y-charbBBOX v-if="item.chartsType === 'charbB'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-charbBBOX>
                    <Y-charDSJZBOX v-if="item.chartsType === 'charDSJZ'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-charDSJZBOX>
                    <Y-histogramBOX v-if="item.chartsType === 'histogram'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-histogramBOX>
                    <Y-lineChartBOX v-if="item.chartsType === 'lineChart'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-lineChartBOX>
                    <Y-lineHistogramBOX v-if="item.chartsType === 'lineHistogram'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-lineHistogramBOX>
                    <Y-linkageShareBOX v-if="item.chartsType === 'linkageShare'" :chartsData="item.options" :webChartsId="item.webConfig.chartsId" @dblclick.native="chartsDbClick(item)"></Y-linkageShareBOX>
                </div>
            </div>
            <div style="margin-bottom: 100px"></div>
        </div>
        <el-drawer
            title="筛选"
            :visible.sync="searchDrawer"
            direction="btt"
            size="50%"
        >
            <Y-searchForm :chartsType="chartsType" :filterPropList="filterPropList" :chartSet="chartSet" :dataSourceOption="dataSourceOption" @screeningClick="screeningClick" @searButton="searButton"></Y-searchForm>
        </el-drawer>
        <el-drawer
            title="数据表格"
            :visible.sync="tableDrawer"
            direction="btt"
            size="50%"
        >
             <el-table v-if="fieldPropConfigs && fieldPropConfigs.length" :data="tableData" height="100%" border style="width: 100%">
                <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
                <el-table-column
                    v-for="(item, index) in fieldPropConfigs"
                    :key="index"
                    :prop="item.fname"
                    :label="item.showName"
                >
                </el-table-column>
            </el-table>
            <el-pagination
                style="margin-top:20px"
                v-if="fieldPropConfigs && fieldPropConfigs.length"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background
                :current-page="pageObj.pageNum"
                :page-sizes="[20, 50, 100, 200]"
                :page-size="pageObj.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageObj.totalRecord">
            </el-pagination>
        </el-drawer>
    </div>
</template>
<script>
import { getChartsList, getChartsData, getCharData } from "@/api/moveCharts/moveCharts.js"
import { getdataSetlist } from "@/api/dataSet/index.js"
import { getChartTablepost } from "@/api/chart/index.js"
import clone from "clone"
export default {
    name: 'moveCharts',
    data () {
        return{
            paramsName: "",
            eChartsData: [],
            searchDrawer: false,
            tableDrawer: false,
            tableData: [],
            chartsType: '',
            filterPropList: [],
            chartSet: {},
            dataSourceOption: [],
            fieldPropConfigs: [],
            pageObj:{
                pageNum: 1,
                pageSize: 20,
                totalRecord: 0
            },
            chartsData: '',
            chartsIndex: 0
        }
    },
    methods: {
        // 筛选
        screeningClick (data) {
            let chartsDataID
            let chartsData = clone(this.eChartsData[this.chartsIndex])
            if (data.datasetId) {
                chartsDataID = data.datasetId
                delete data.datasetId
            } else if (data.dataset) {
                chartsDataID = data.dataset
                delete data.dataset
            }
            let chartsSearch = {
                chartSet: data,
                datasetId: chartsDataID,
                filters: chartsData.filterPropList,
                type: chartsData.chartsType,
            }
            getCharData(chartsSearch).then(res => {
                this.eChartsData[this.chartsIndex].options = res
                this.searchDrawer = false
            })
        },
        // 搜索
        searButton (data) {
            let newdata = clone(data)
            for (let i = 0; i < newdata.length; i ++) {
                if (newdata[i].time) {
                    newdata[i].betweenValue[0] = clone(newdata[i].time.startDate)
                    newdata[i].betweenValue[1] = clone(newdata[i].time.endDate)
                    delete newdata[i].time
                }
                if (newdata[i].inputType) {
                    delete newdata[i].inputType
                }
            }
            let chartsDataID
            let chartsData = clone(this.eChartsData[this.chartsIndex])
            if (chartsData.chartSet.datasetId) {
                chartsDataID = chartsData.chartSet.datasetId
                delete chartsData.chartSet.datasetId
            } else if (chartsData.chartSet.dataset) {
                chartsDataID = chartsData.chartSet.dataset
                delete chartsData.chartSet.dataset
            }
            let chartsSearch = {
                chartSet: chartsData.chartSet,
                datasetId: chartsDataID,
                filters: newdata,
                type: chartsData.chartsType,
            }
            getCharData(chartsSearch).then(res => {
                this.eChartsData[this.chartsIndex].options = res
                this.searchDrawer = false
            })
        },
        searchClick (item, index) {
            this.chartsIndex = index
            item.chartSet.datasetId = item.datasetId
            this.chartSet = item.chartSet
            this.filterPropList = item.filterPropList
            this.chartsType = item.chartsType
            this.searchDrawer = true
        },
        chartsDbClick (item) {
            this.tableDrawer = true
            this.chartsData = item
            this.getChartTableFunction(item)
        },
        getChartTableFunction(data) {
            let datasetId
            if (data && data.datasetId) {
                datasetId = data.datasetId
            } else {
                datasetId = data.dataset
            }
            let params = {
                id: datasetId,
                pageNum: this.pageObj.pageNum,
                pageSize: this.pageObj.pageSize
            }
            this.fieldPropConfigs = []
            this.tableData = []
            for (let i = 0; i < data.filterPropList.length; i ++) {
                data.filterPropList[i].betweenValue = null
            }
            getChartTablepost(params, data.filterPropList).then(res => {
                this.drawerBottom = true
                this.fieldPropConfigs = res.fieldPropConfigs
                this.tableData = res.result
                this.pageObj.totalRecord = res.totalRecord
                this.pageObj.pageNum = res.pageNum
            })
        },
        handleSizeChange(val) {
            this.pageObj.pageSize = val
            this.getChartTableFunction(this.chartsData)
        },
        // 点击页码
        handleCurrentChange(val) {
            this.pageObj.pageNum = val
            this.getChartTableFunction(this.chartsData)
        },
        quitClick () {
            window.history.back(-1); 
        },
        init () {
            let params = {
                catId: this.$route.params.id
            }
            getChartsList(params).then(res => {
                let data = []
                for (let i = 0; i < res.length; i ++) {
                    let parameters = {
                        chartSet: {},
                        datasetId: "",
                        filters: [],
                        type: ""
                    }
                    parameters.chartSet = res[i].chartSet
                    parameters.datasetId = res[i].datasetId
                    parameters.type = res[i].chartsType
                    data.push(parameters)
                }
                let newres = res
                getChartsData(data).then(res => {
                    for (let i = 0; i < newres.length; i ++) {
                        newres[i].options = res[i]
                    }
                    this.eChartsData = newres
                })
            })
            getdataSetlist().then(res => {
                let newRes = []
                for (let i = 0; i < res.length; i ++) {
                    let data = {
                        text: res[i].dsName,
                        value: res[i].id
                    }
                    newRes.push(data)
                }
                this.dataSourceOption = newRes
            })
        },
    },
    created () {
        this.paramsName = this.$route.params.name
        this.init()
    },
}
</script>
<style lang="less" scoped>
@font-size-base: 75;
.moveCharts {
  width: 100%;
  height: 100%;
//   padding-top: 22%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.headerBox {
    // position: absolute;
    // top: 0;
    width: 10rem;
    height: 1.4rem;
    background: rgb(87,173,247);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: .466667rem;
    font-weight: 600;
}
.namedesBox {
    // position: absolute;
    // top: 8%;
    width: 10rem;
    height: 1rem;
    border-bottom: 1px solid rgb(218, 218, 218);
    display: flex;
    align-items: center;
    font-size: .373333rem;
    font-weight: 400;
    i {
        margin-left: .3rem;
    }
    span {
        flex: 1;
        text-align: center;
        padding-right: .7rem;
        box-sizing: border-box;
    }
}
.actionBox {
    flex: 1;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    overflow-y: scroll;
    padding: 5px;
    box-sizing: border-box;
    .chartsBox {
        width: 100%;
        // height: 300px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        .searchChartsBox {
            width: 10rem;
            height: .5rem;
            // border-bottom: 1px solid rgb(221, 221, 221);
            padding: 0 .5rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: .533333rem;
        }
        .chartsActionBox {
            // flex: 1;
            border-bottom: 1px solid rgb(221, 221, 221);
        }
    }
}
/deep/ .el-drawer__header {
    margin-bottom: 5px;
    font-size: .533333rem;
}
</style>